@use 'variables' as base;

.form {
  width: 300px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.form h2 {
  text-align: center;
  margin-bottom: 20px;
}

.form label {
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
}

.form label.checkbox {
  display: inline-flex;
  width: 300px;
}

.form input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
  box-sizing: border-box;
}

.form input.checkbox {
  width: auto;
}

.form button {
  margin-top: 10px;
}

.form .primary-button {
  padding: 10px 20px;
  border: none;
  border-radius: 3px;
  background-color: base.$primary-button-color;
  color: #fff;
  cursor: pointer;
}

.form .secondary-button {
  cursor: pointer;
}

.error {
  font-size: 1rem;
  color: base.$error;
  padding: 10px 0 10px;
}
