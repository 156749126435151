.timeline-list {
  list-style: none;
}

.timeline-list__item {
  padding-bottom: 1.5rem;
  position: relative;
}

.timeline-list__status {
  /* border-radius: 50%;
  border: 1px dashed black;
  padding: 5px 10px;
  margin-right: 10px;
  background:#fff */
  display: inline-block;
  border-radius: 50%;
  height: 0.6rem;
  width: 0.6rem;
  margin-right: 0.5rem;
  background-color: lightgray;
}
.timeline-list__status:before {
  /* content:'';
  position:absolute;
  border-left:1px solid ;
  left:14px;
  bottom:0;
  z-index:-1;
  height:100% */
  content: '';
  position: absolute;
  border-left: 0.05rem solid gray;
  left: 0.29rem;
  /* bottom: 20em; */
  z-index: -1;
  height: calc(100% - 1.6em);
  top: 1.6rem;
  /* margin: 1.6em 0 3.2em 0; */
  box-sizing: border-box;
}

.timeline-list__item:last-child span:before {
  content: none;
}
.timeline-list__item:last-child {
  padding-bottom: 0;
}
