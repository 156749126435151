@use 'variables' as base;

.toast {
  background-color: #333;
  color: #fff;
  padding: 10px 20px;
  border-radius: 10px;
  position: fixed;
  top: 20px;
  right: 50%;
  z-index: 9999;
  animation: slideIn 5s ease-in-out, slideOut 5s ease-in-out forwards;
}

.fail {
  border: 3px solid base.$error;
  color: base.$error;
  background-color: #fff;
  font-weight: bold;
}

.success {
  border: 3px solid base.$success;
  color: base.$success;
  background-color: #fff;
  font-weight: bold;
}

@keyframes slideIn {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slideOut {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
    opacity: 0;
  }
}
